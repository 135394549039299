import React from "react";
import Dropzone from "react-dropzone";
import { Progress } from 'reactstrap';
import {css} from '@emotion/core';
import {FadeLoader} from "react-spinners";

import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

import Translator from "bazinga-translator";
const translationsConfig = require("../../../../../../../js/translations/fr.json");
Translator.fromJSON(translationsConfig);

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

const maxSize = 8000000000;
const sliceSize = 5242880;

export default class Uploader extends React.Component {
    state = {
        entityId: this.props.entityId,
        files: [],
        progressFiles: [],
        entityFiles: [],
        total: this.props.total,
        isLoading: false
    };


    componentDidMount() {
        this.getEntityFiles();
    }

    /**
     * List actual Project files
     *
     * @returns {Promise<void>}
     */
    getEntityFiles = async () => {
        this.setState({
            isLoading: true
        });

        try {
            let response = await fetch(Routing.generate("uploader_bundle_project_get_files", { id: this.props.entityId }), {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status == 200) {
                let data = await response.json();

                this.setState({
                    entityFiles: data,
                    isLoading: false
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Remove an actual Files
     *
     * @param fileId
     * @returns {Promise<void>}
     */
    onDeleteEntityFile = async fileId => {
        this.setState({
            isLoading: true
        });

        try {
            let response = await fetch(Routing.generate("uploader_bundle_project_remove_file", { id: fileId }), {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status == 200) {
                let data = await response.json();

                this.setState({
                    entityFiles: data,
                    isLoading: false
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    onDrop = (filesAccepted, rejectedFiles) => {
        let files = this.state.files;
        let progressFiles = this.state.progressFiles;

        filesAccepted.forEach(file => {
            if (file.size <= maxSize) {
                files.push(file);

                progressFiles.push({
                   name: file.name,
                   uploadPercent: 0
                });
            }
        });

        this.setState({
            files: files,
            progressFiles: progressFiles
        });
    };

    onDeleteFile = index => {
        let files = this.state.files;

        files.splice(index, 1);

        this.setState({
            files: files
        });
    };

    submitFiles = async () => {
        if (this.state.files.length === 0) {
            return;
        }

        this.state.files.map((file, index) => {
            this.processFile(file);
        });
    };

    processFile = async (file) => {
        let size = file.size;
        let start = 0;

        await this.cutFileInChunk(file, sliceSize, start, size);
    }

    cutFileInChunk = async (file, sliceSize, start, size) => {
        let end = start + sliceSize;

        if (size - end < 0) {
            end = size;
        }

        var s = this.slice(file, start, end);

        await this.sendChunkFile(s, start, end, file);

        if (end < size) {
            start += sliceSize;

            setTimeout(() => {
                this.cutFileInChunk(file, sliceSize, start, size);
            }, 1);
        }
    }

    sendChunkFile = async (piece, start, end, file) => {
        let formData = new FormData();

        try {
            let url = Routing.generate("uploader_bundle_project_add_file", {id: this.props.entityId});

            formData.append('startChunk', start);
            formData.append('endChunk', end);
            formData.append('chunk', piece);
            formData.append('fileName', file.name);
            formData.append('fileSizeTotal', file.size);

            let response = await fetch(url, {
                method: "POST",
                body: formData,
                credentials: "same-origin"
            });

            if (response.status == 200) {
                let responseData = await response.json();

                let progressFiles = [];

                this.state.progressFiles.forEach((item, index) => {
                    if(item.name === file.name) {
                        item.uploadPercent = responseData.uploadPercent;
                    }

                    progressFiles.push(item);
                });

                this.setState({
                    progressFiles: progressFiles
                }, () => {
                    if(responseData.uploadPercent === 100) {
                        this.getEntityFiles();
                    }
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Formalize file.slice
     */
    slice = (file, start, end) => {

        let slice = file.mozSlice ? file.mozSlice :
            file.webkitSlice ? file.webkitSlice :
                file.slice ? file.slice : noop;

        return slice.bind(file)(start, end);
    }

    render() {
        const entityFiles = this.state.entityFiles.map((file, index) => {
            return (
                <div key={index} className="col-md-3 mb-2 text-center">
                    <div className="mb-4 text-center">
                        <i className="fas fa-4x fa-file-alt"></i>
                        <br />
                        <a href={file.url} target="_blank">{file.fileName}</a>
                    </div>

                    <button
                        className="btn btn-primary"
                        data-toggle="tooltip"
                        onClick={this.onDeleteEntityFile.bind(this, file.fileId)}
                        title={Translator.trans("app.action.delete")}
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </div>
            );
        });

        const files = this.state.files.map((file, index) => {

            let percent = 0;

            this.state.progressFiles.forEach((item, index)  => {  if(item.name === file.name) percent = item.uploadPercent });
            console.log(percent);

            return (
                <div key={index} className="col-md-3 mb-2">
                    <div className="mb-4 text-center">
                        <i className="fas fa-4x fa-file-alt"></i>
                        <br />
                        <a href={URL.createObjectURL(file)} target="_blank">{file.name}</a>
                    </div>
                    <div className="mt-2 mb-2">
                        {
                            percent === 100 ?
                                <Progress value={percent} color="success">{percent} %</Progress>
                                :
                                <Progress value={percent}>{percent} %</Progress>
                        }
                    </div>

                    {
                        percent !== 100 ?
                            <button
                                className="btn btn-primary"
                                data-toggle="tooltip"
                                onClick={this.onDeleteFile.bind(this, index)}
                                title={Translator.trans("app.action.delete")}
                            >
                                <i className="fas fa-trash"></i>
                            </button>
                        :
                            ''
                    }
                </div>
            );
        });

        const submit = this.state.files.length > 0 && (
            <div className="mb-4">
                <button
                    className="btn btn-primary"
                    data-toggle="tooltip"
                    onClick={this.submitFiles.bind(this)}
                    title={Translator.trans("uploader_bundle.project.uploader.action.send")}
                >
                    {Translator.trans("uploader_bundle.project.uploader.action.send")}
                </button>
            </div>
        );

        return (
            <div>
                <div className="card mb-2 mt-4">
                    <div className="card-header">{Translator.trans("uploader_bundle.project.uploader.title")}</div>
                    <div className="card-body">
                        <div className="text-center mb-4">{Translator.trans("uploader_bundle.project.uploader.info")}</div>
                        <div className="container-dropzone-react mb-4">
                            <Dropzone onDrop={this.onDrop.bind(this)} className="dropzone-react" />
                            <i className="fas fa-upload fa-dropzone-react"></i>
                        </div>
                        <div className="text-center">
                            <div className="mb-4">En attente :</div>
                            <div className="row mb-4">{files}</div>
                            {submit}
                        </div>
                    </div>
                </div>
                <div className="card mt-4">
                    <div className="card-header">
                        {Translator.trans("uploader_bundle.project.uploader.files")}
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {
                                this.state.isLoading ?
                                    <FadeLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={500}
                                        color={'#004788'}
                                        loading={this.state.isLoading}
                                    />
                                :
                                    entityFiles
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
