import React from "react";

import { ContentExplorer } from "./components/cms/ContentExplorer.jsx"
import { MediaExplorer } from "./components/cms/MediaExplorer.jsx"
import { PageBuilder } from "./components/cms/PageBuilder.jsx"
import { PageManager } from "./components/cms/PageManager.jsx"
import { MenuBuilder } from "./components/cms/MenuBuilder.jsx"
import Uploader from "./components/uploader/Uploader";
import { render } from "react-dom";
import { SeoChecker } from "./components/cms/SeoChecker.jsx"

new ContentExplorer();
new MediaExplorer();
new PageBuilder();
new MenuBuilder();

/**
 * Rendering component React EntityPictureManager
 */
var uploaderElement = document.getElementById("component-uploader");

if (uploaderElement) {
    render(
        <Uploader
            entityId={uploaderElement.dataset.entityId}
        />,
        uploaderElement
    );
}

new PageManager();
new SeoChecker();
